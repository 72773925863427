import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import HeroSection from "../components/Home/HeroSection";
import BlockAboutOne from "../components/About/BlockAboutOne";
import Values from "../components/About/Values";
import Directories from "../components/Home/Directories";
import BlockHomeOne from "../components/Home/BlockHomeOne";
import Anuncio from "../components/Home/Anuncio";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import VideoPromocional from "../components/global/VideoPromocional";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import PaleteaColor from "../components/global/Paletacolor"
import ServicesBlock from '../components/Home/ServicesBlock_2'
import BlockHomeTwo from "../components/Home/BlockHomeTwo";

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <Modal />
        <HeroSection />
        <Anuncio/>
        <BlockHomeOne />
        <Directories />
        <VideoPromocional
          title={rpdata?.dbSlogan?.[2]?.slogan}
          text={rpdata?.dbAbout?.[1]?.text}
          linkVideo={`${rpdata?.videosPromo?.[0].link}`}
          image={rpdata?.stock?.[1]}
        />
        <BlockAboutOne />
        <div className="mt-[10px]">
          <Values />
        </div>
        <BlockHomeTwo />
        <div>
          <h2 className="text-center">Our Color Palette</h2>
        </div>
        <PaleteaColor />
        <ServicesBlock />
        <div className="md:my-6 md:px-14">
          <h2 className="text-center">what our customers say</h2>
          <div class="elfsight-app-cb69f1e5-27ca-464b-af78-3191adccba7d"></div>
        </div>
        {/* <ServicesHome /> */}
        <div className="py-[100px] pintura" >
          <FormHome />
        </div>
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
