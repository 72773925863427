import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import { TbPaint } from 'react-icons/tb'


function BlockAboutOne() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <section className="w-full flex justify-center md:py-32 py-10">
        <div className="max-w-6xl md:flex md:p-0 px-2 content-reverse">
        <div className="md:w-[50%] relative">
            <div
              className="w-full md:w-[600px] md:h-[600px] h-[350px] bg-cover bg-center rounded-full md:-ml-10 ml-0"
              style={{ backgroundImage: `url("${rpdata?.stock?.[5]}")` }}
            ></div>
            <div
              className="md:w-[280px] md:h-[280px] w-[150px] h-[150px]  absolute md:-left-[100px] border-red-700 border-[15px] bg-cover bg-center rounded-full md:top-4 bottom-0 md:flex  -z-50"
            ></div>


          </div>
          <div className="md:w-[50%] py-4 md:px-6 px-3 self-center md:text-start text-center">
            <h2 className="separator-about">A Little About Us</h2>
            <p>{rpdata?.dbAbout?.[0].text}</p>
            <div className="flex md:flex-row  py-8 ">
              <div className="md:w-[50%] w-full">
                <ul className="list-inner-section pl-3 text-start">
                {
                  rpdata?.dbAbout?.[0].list.length > 1 ?
                    rpdata?.dbAbout?.[0].list.map((item, index) => {
                      return (

                        <li key={index} className="py-2 flex items-center">
                          <TbPaint style={{fontSize:"30px"}} />
                          <span className="pl-2">{item}</span>
                        </li>
                    )
                  })
                : null
                }
              </ul>
            </div>
            <div className="md:w-[50%] rounded-xl bg-cover brocha border-white ">
              
            </div>
            </div>
            <ButtonContent />
            <div className="w-full">
              <span className="separator-about"></span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlockAboutOne;
