/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

const Map = (props) => {
 const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full mt-[60px] mb-16">
        <h2 className="text-center">We Cover {rpdata?.dbPrincipal?.miles} Miles Around:</h2>
        <div className="md:flex w-full px-5 gap-x-5 block py-6">
          <div className=" md:w-[33%] w-[100%]">
            <h2 className="text-center"> {rpdata?.dbPrincipal?.location?.[1].address}</h2>
            <iframe src={rpdata?.dbPrincipal?.location?.[1].url} className="w-full h-[500px]"/>
          </div>
          <div className=" md:w-[33%] w-[100%]">
            <h2 className="text-center"> {rpdata?.dbPrincipal?.location?.[2].address}</h2>
            <iframe src={rpdata?.dbPrincipal?.location?.[2].url} className="w-full h-[500px]"/>
          </div>
          <div className=" md:w-[33%] w-[100%]">
            <h2 className="text-center"> {rpdata?.dbPrincipal?.location?.[3].address}</h2>
            <iframe src={rpdata?.dbPrincipal?.location?.[3].url} className="w-full h-[500px]"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Map
