import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import SocialMedia from "../global/SocialMedia";
import Form from "./Form";
import { FiNavigation, FiPhoneCall } from 'react-icons/fi';
import { AiFillClockCircle } from 'react-icons/ai'
import { FaCalendarAlt } from 'react-icons/fa'


function ContactInfo() {
  const { rpdata } = useContext(GlobalDataContext);

  const contentInfo = [
    {
      title: 'Location',
      subTitle: rpdata?.dbPrincipal?.location?.[0].address,
      icon: <FiNavigation fontSize={45} color={'var(--secondary-color)'} />,
      links: '',
    },
    {
      title: 'Phone',
      subTitle: rpdata?.dbPrincipal?.phones?.[0].phone,
      icon: <FiPhoneCall fontSize={45} color={'var(--main-color)'} />,
      links: `tel:+1${rpdata?.dbPrincipal?.phones?.[0].phone}`,
    },
    {
      title: 'Phone',
      subTitle: rpdata?.dbPrincipal?.phones?.[1].phone,
      icon: <FiPhoneCall fontSize={45} color={'var(--main-color)'} />,
      links: `tel:+1${rpdata?.dbPrincipal?.phones?.[1].phone}`,
    },
    {
      title: 'Workdays',
      subTitle: rpdata?.dbPrincipal?.workdays?.[0].day,
      icon: <FaCalendarAlt fontSize={45} color={'var(--main-color)'} />,
      links: '',
    },
    {
      title: 'Work Hours',
      subTitle: rpdata?.dbPrincipal?.workHours?.[0].hour,
      icon: <AiFillClockCircle fontSize={45} color={'var(--secondary-color)'} />,
      links: '',
    },
  ]

  return (
    <>
      <div className="w-full md:px-14 pt-8">
        <h2 className="text-center">Get In Touch!</h2>
        <div className="w-[90%] mx-auto md:flex md:gap-5 justify-center block" >
          {
            contentInfo.map((items, index) => {
              return (
                <div className="px-5 py-8 shadow-lg  rounded-xl" key={index}>
                  <div className="flex">
                    <div className="bg-[black] h-[1px] w-[20%] self-center mr-3"></div>
                    <h5 className="text-[black]">{items.title}</h5>
                  </div>
                  {
                    items.links.length > 1 ?
                      <a href={`${items.links}`}>
                        <div className="flex space-x-4">
                          {items.icon}
                          <h6 className="text-black">{items.subTitle}</h6>
                        </div>
                      </a>
                      :
                      <div className="flex space-x-4">
                        {items.icon}
                        <h6 className="text-black">{items.subTitle}</h6>
                      </div>
                  }
                </div>
              )
            })
          }
        </div>

        

        <div className="flex flex-col justify-center items-center text-white my-6">
          <h4 className="text-center text-black text-3xl">Follow Us</h4>
          <ul className="flex">
          {rpdata?.dbSocialMedia?.redes.map((item, index) => {
            return (
              <li key={index} className='px-3 py-1 rounded-full fondo'>
                <a href={item.url} target="_blank" rel='noopener noreferrer' >
                  <i
                    className={`fab fa-${item.icon}`}
                    aria-hidden="true"
                  />
                </a>
              </li>
            );
          })}
        </ul>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <div className="flex w-[1100px] py-14 md:flex-row flex-col text-center md:text-start">
          <div
            className="
              md:w-[50%]
              w-full
              md:px-14
              px-2
              bg-center
              bg-cover
            "
            style={{ backgroundImage: `url("${rpdata?.stock?.[4]}")` }}
          ></div>
          <div className="md:w-[50%] w-full ml-3 md:px-10 px-2 border-solid border-2 border-slate-900 rounded-[40px]">
            <h3>Send Us A Message</h3>
            <Form />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactInfo;
