import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function Anuncio() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <section className="w-full mx-auto md:py-32 py-10 justify-center">
        <div className="md:flex md:flex-row flex flex-col md:p-0 px-2 content-reverse mx-auto justify-center">
        

          <div className="md:w-[80%] z-50 rounded-2xl shadow2 bg-white md:-mt-[15%] -mt-[22%] py-4 md:px-3 px-2 self-center text-center">
            <h2 className="text-black">With your help, we bring love and hope to the</h2><h2>"Boys & Girls Clubs of America,"</h2><h2 className="text-black">in the community of Elizabethton, Tennessee.</h2>  
            <h5 className="text-black0">- let's continue to work together to support this organization -</h5>
          </div>

        </div>
      </section>
    </>
  );
}

export default Anuncio;