import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { ImgComparisonSlider } from "@img-comparison-slider/react";

const GalleryComponent = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      {
        rpdata?.beforeAfterGall?.length > 0 ?
          <div className="w-[90%] mx-auto py-14">
            <h2 className="text-center capitalize pb-[50px]">Before & after</h2>
            <div className={`flex flex-wrap justify-center gap-5`}>
              {
                rpdata?.beforeAfterGall?.map((item, index) => {
                  return (
                    <ImgComparisonSlider key={index}>
                      <div slot="first" class="before">

                        <img slot="first" className="w-full h-full md:w-[400px] md:h-[400px]" src={`${item.beforeImg}`} />
                        <figcaption>Before</figcaption>
                      </div>
                      <div slot="second" class="after">

                        <img slot="second" className="w-full h-full md:w-[400px] md:h-[400px]" src={`${item.afterImg}`} />
                        <figcaption>After</figcaption>
                      </div>
                    </ImgComparisonSlider>
                  )
                })
              }
            </div>

          </div>
          : null

      }
      {/* <div className="w-[90%] mx-auto py-14 my-16 border-black border-[12px]">
        <div className={`flex flex-wrap justify-center gap-5`}>
          {

          }
        </div>
      </div> */}
      <h2 className="text-center pt-[100px]">Our Recent Projects</h2>
      <div className="py-[100px] flex justify-center">
        <div className="gallery-content w-4/5 mx-auto pb-12">
          <Gallery>
            {
              rpdata?.gallery?.length > 0 ? rpdata?.gallery?.map((item, index) => (
                <Item
                  key={index}
                  original={item}
                  thumbnail={item}
                  width="1024"
                  height="550"
                  padding="10px"
                >
                  {({ ref, open }) => <img ref={ref} onClick={open} src={item} alt="Not Found" />}
                </Item>
              ))
                : rpdata?.stock?.map((item, index) => (
                  <Item
                    key={index}
                    original={item}
                    thumbnail={item}
                    width="1024"
                    height="550"
                    padding="10px"
                  >
                    {({ ref, open }) => <img ref={ref} onClick={open} src={item} alt="Not Found" />}
                  </Item>
                ))
            }
          </Gallery>
        </div>
      </div>
    </>
  );
};

export default GalleryComponent;
