import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";


function BlockAboutTwo() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div className="">
      <section className="w-full flex justify-center md:py-32 mb-32">
        <div className="max-w-7xl md:flex md:flex-row flex flex-col md:p-0 md:px-6 px-2 content-reverse">
          <div className="md:w-[40%] block md:flex relative z-10">


            <div
              className="w-[300px] h-[300px] md:w-[500px] md:h-[500px] ml-8 md:-mr-8 justify-center bg-cover bg-center rounded-full bloqueshaw"
              style={{ backgroundImage: `url("${rpdata?.stock?.[2]}")` }}
            ></div>
            
          </div>
          <div className="md:w-[60%] py-4 md:pl-14 md:pr-6 px-3 self-center md:text-start text-center bg-white shadow-2xl rounded-xl">
            <h2 className="separator-about text-blue-800">{rpdata?.dbSlogan?.[0].slogan}</h2>
            <p className="">{rpdata?.dbAbout?.[1].text}</p>
            <ButtonContent />

            <div className="w-full">
              <span className="separator-about"></span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BlockAboutTwo;
