import React from 'react'


const colorInfo = [
    {
        link: 'https://images.sherwin-williams.com/content_images/sw-pdf-sherwin-williams-colorc.pdf',
        image: 'https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/DIrectorios%2FSW-1.jpg?alt=media&token=e4d94a47-3fbf-4285-b5c4-0b1735a40525',
    },
    {
        link: 'https://www.ppgpaints.com/color',
        image: 'https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/PPG.png?alt=media&token=426f9347-bbf6-498a-9d8b-ab26808eef90',
    },
]


const Paletacolor = () => {
    return (
        <div className='w-4/5 mx-auto md:flex md:flex-wrap justify-center items-center gap-6 my-10  block'>
            {
                colorInfo.map((item, index) => {
                    return (
                        <a href={item.link} target="_blank" rel="noreferrer" key={index}>
                            <img src={item.image} alt='no found' />
                        </a>
                    )
                })
            }

        </div>
    )
}


export default Paletacolor;